<template>
  <v-container>
    <v-card class="pa-0" :loading="loading">
      <v-card-title>
        Pricing
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta Title"
              v-model="meta_data.meta.title"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta description"
              v-model="meta_data.meta.description"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          label="Title"
          v-model="meta_data.title"
          outlined
          dense
        ></v-text-field>

        <p class="font-weight-bold">Price Description</p>
        <vue-editor
          v-model="meta_data.description"
          style="height: 70vh;"
          :editorToolbar="customToolbar"
        >
        </vue-editor>
        <v-btn
          class="mr-4 mt-14"
          color="secondary"
          @click="updateMetaPage()"
          :loading="loading"
          :disabled="loading"
          >submit</v-btn
        >
      </v-card-text>
    </v-card>

    <v-card class="pa-0 mt-3">
      <v-card-title>
        Price List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:300ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :options.sync="pagination"
        :footer-props="{ 'items-per-page-options': [30, 45, 60, -1] }"
        :loading="loading"
        item-key="id"
        class="elevation-1 page__table"
        :show-select="false"
        :disable-pagination="true"
        :hide-default-footer="true"
      >
         <template v-slot:body="">
          <draggable
            :list="items.results"
            v-bind="dragOptions"
            tag="tbody"
            @change="changeOrder"
          >
            <tr
              v-for="(member, index) in items.results"
              :key="index"
              style="cursor: move !important;"
            >
              <td>
                <v-icon style="cursor: pointer;" title="Drag">
                  mdi-drag 
                </v-icon>
              </td>
              <td>
                {{ member.title }}
              </td>
              <td>
                {{ member.price ? member.price : "N/A" }}
              </td>
              <td>
                <v-btn
                  color="grey darken-2"
                  icon
                  dark
                  :to="'/pricing/edit/' + member.id"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="grey darken-2" icon dark @click="confirmDelete(member)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"
          >Are you sure delete <b>{{ pricing.title }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(pricing)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="primary" fixed fab bottom right to="/pricing/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { parseParams } from "../../plugins/helper";
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";

export default {
  name: "Pricing",
  mounted() {
    this.getMetaPageDetails();
  },
  components: {
    VueEditor,
    draggable
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "title",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    pagination: {
      handler: function() {
        this.getItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      meta_data: {
        title: "",
        description: "",
        meta: {
          title: "",
          description: "",
        },
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
      ],
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      pagination: {
        itemsPerPage: -1,
        sortBy: ["order"],
        page: 1,
        filters: {},
      },
      headers: [
        { text: '', value: '', sortable: false },
        { text: "Title", value: "title", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      dialog: false,
      pricing: {
        name: null,
      },
      search: "",
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateMetaPage() {
      console.log(this.meta_data);
      let _self = this;
      this.loading = "secondary";
      this.$axios
        .put(`admin/metapage/edit/${this.meta_data.id}`, this.meta_data)
        .then((response) => {
          if (response.data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/pricing");
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getMetaPageDetails() {
      let _self = this;
      this.loading = "secondary";
      this.$axios
        .get("/admin/metapages/PRICING")
        .then((response) => {
          _self.meta_data = response.data.data;
          var doc = new DOMParser().parseFromString(
            _self.meta_data.description,
            "text/html"
          );
          _self.meta_data.description = doc.documentElement.textContent;
          _self.loading = false;
        })
        .catch(function() {
          _self.loading = false;
        });
    },
    getItems() {
      let _self = this;
      this.loading = true;
      let url = parseParams(this.pageData);
      this.$axios
        .get("/admin/price/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function() {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.pricing = item;
    },
    deleteItem(item) {
      console.log(item);
      const index = this.items.results.indexOf(item);
      let _self = this;
      _self.loading = true;
      this.$axios
        .delete("/admin/price/delete/" + item.id)
        .then((res) => {
          if (res.status) {
            _self.items.results.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function() {
          _self.loading = false;
        });
    },
    changeOrder() {
      let _self = this;
      _self.loading = true;

      let new_order = [];

      for(let i=0; i<_self.items.results.length; i++) {
        new_order.push({
          index: i,
          id: _self.items.results[i].id
        });
      }

      this.$axios
        .post("/admin/price/change_order", {
          new_order: new_order
        })
        .then((res) => {
          if (res.status) {
           res 
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    }
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 1;
  background: #dadada80 !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff;
}
</style>